<template>
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <section class="chat-app-window ai-chat-app-window">
        <!-- Chat Content -->
        <div class="active-chat w-100">
            <!-- Message Input -->
            <b-form class="chat-app-form" @submit.prevent="getImage">
                <b-row>
                    <b-col cols="12" md="2">
                        <b-form-group>
                            <label for="">{{ $t('Select AI Model') }}</label>
                            <b-form-select v-model="aiModel" :options="modelOpts"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                        <b-form-group>
                            <label for="">{{ $t('Select Style') }}</label>
                            <b-form-select v-model="style" :options="styleOpts"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                        <b-form-group>
                            <label for="">{{ $t('Select Mood') }}</label>
                            <b-form-select v-model="mood" :options="moodOpts"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                        <b-form-group>
                            <label for="">{{ $t('Select Size') }}</label>
                            <b-form-select v-model="resolution" :options="resolutionOpts"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <!-- <b-col cols="12" md="2">
                        <b-form-group>
                            <label for="">No of Result(s)</label>
                            <b-form-select v-model="result" :options="resultOpts" :disabled="aiModel == 'dall-e-3' || aiModel == 'dall-e-2'"></b-form-select>
                        </b-form-group>
                    </b-col> -->
                </b-row>
                <b-row class="mb-3">
                    <b-col cols="12" md="6">
                        <b-input-group class="input-group-merge form-send-message mr-1 mb-2">
                            <b-form-input :disabled="loading" v-model="title" name="find_image" placeholder="Describe your image" />
                        </b-input-group>
                    </b-col>
                    <b-col cols="12" md="2">
                        <b-button block variant="primary" class="mb-2" type="submit" :disabled="isEmpty(title) || loading">
                            {{ $t('Generate') }}
                        </b-button>
                    </b-col>
                    <b-col cols="12" md="2">
                        <b-button block variant="success" type="button" :disabled="loading" :to="{name: `${route_type}-ai-images-saved`}">
                            {{ $t('Saved') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
            <b-row v-if="isEmpty(aiImage.images)">
                <b-col cols="12" class="mb-1 d-flex justify-content-center align-content-center align-items-center align-self-center text-center">
                    <img :src="`${$apiUrl}/images/common/MOM AI Chat.svg`" style="max-width: 50vw" />
                </b-col>
                <b-col cols="12" class="mb-1 d-flex justify-content-center align-content-center align-items-center align-self-center text-center">
                    <h3 class="text-primary font-weight-bolder">Begin with MOM AI - Let's Go!</h3>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col cols="12" md="3" v-for="(aiImageTree, ik) in aiImage.images" :key="`saved_images_${ik}`" class="mb-1">
                    <GeneratedImage :aiImageTree="aiImageTree" />
                </b-col>
            </b-row>
            <div style="display:block;width:100%;height:10vh;clear: both;"></div>
        </div>
    </section>
</template>
  
<script>
const modelOpts = [
    {
        value: 'dall-e-3', text: "DALL-E-3", resolutionOpts: [
            {value: '1024x1024', text: '1024x1024', disabled: false},
            {value: '1024x1792', text: '1024x1792', disabled: false},
            {value: '1792x1024', text: '1792x1024', disabled: false},
        ]
    },
    {
        value: 'dall-e-2', text: "DALL-E-2", resolutionOpts: [
            /* {value: '256x256', text: '256x256', disabled: false},
            {value: '512x512', text: '512x512', disabled: false}, */
            {value: '1024x1024', text: '1024x1024', disabled: false},
        ]
    },
]
const styleOpts = [
    {value: null, text: 'Select Style', disabled: true},
    {value: 'none', text: 'None', disabled: false},
    {value: 'Abstract', text: 'Abstract', disabled: false},
    {value: 'Realstic', text: 'Realstic', disabled: false},
    {value: 'Cartoon', text: 'Cartoon', disabled: false},
    {value: 'Digital Art', text: 'Digital Art', disabled: false},
    {value: 'Illustration', text: 'Illustration', disabled: false},
    {value: 'Photography', text: 'Photography', disabled: false},
    {value: '3D Render', text: '3D Render', disabled: false},
    {value: 'Pencil', text: 'Pencil', disabled: false},
]
const moodOpts = [
    {value: null, text: 'Select Mood', disabled: true},
    {value: 'none', text: 'None', disabled: false},
    {value: 'Angry', text: 'Angry', disabled: false},
    {value: 'Aggressive', text: 'Aggressive', disabled: false},
    {value: 'Calm', text: 'Calm', disabled: false},
    {value: 'Cheerful', text: 'Cheerful', disabled: false},
    {value: 'Chilling', text: 'Chilling', disabled: false},
    {value: 'Dark', text: 'Dark', disabled: false},
    {value: 'Happy', text: 'Happy', disabled: false},
    {value: 'Sad', text: 'Sad', disabled: false},
]
const resultOpts = [
    {value: 1, text: '1', disabled: false},
    {value: 2, text: '2', disabled: false},
    {value: 3, text: '3', disabled: false},
    {value: 4, text: '4', disabled: false},
    {value: 5, text: '5', disabled: false},
]
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from '@/utilities'
import GeneratedImage from '@/views/users/ai/components/GeneratedImage.vue'
import { showLoader, hideLoader, showDangerNotification, showErrorNotification } from '@/@core/comp-functions/ui/app'
import { log } from 'pdfmake/build/pdfmake'
export default {
    name: 'AIImages',

    components: {
        GeneratedImage
    },

    props: {

    },

    data: () => ({
        title: null,
        chats: [],
        loading: false,
        mood: 'none',
        size: null,
        style: 'none',
        moodOpts,
        styleOpts,
        modelOpts,
        resultOpts,
        resolution: '1024x1024',
        result: 1,
        aiModel: 'dall-e-3',
        aiImage: {},
        imgk: Number(new Date)
    }),

    computed: {
        ...mapGetters('ai', [
            'savedImages',
            'saveImagesLoaded'
        ]),
        resolutionOpts: {
            get(){
                return this.modelOpts.find(i => i.value == this.aiModel).resolutionOpts
            }
        },
        route_type: {
            get(){
                let {type} = this.$route.meta;
                if(isEmpty(type)){
                    type = 'user'
                }
                return type;
            }
        }
    },
    methods: {
        isEmpty,
        ...mapActions('ai', [
            'generateAiImage',
        ]),
        getImage(){
            if(!isEmpty(this.title)){
                this.aiImage = {}
                this.loading = true
                showLoader()
                const payLoad = {
                    ai_model: this.aiModel,
                    title: this.title,
                    mood: this.mood,
                    style: this.style,
                    size: this.resolution,
                    result: this.result,
                }
                this.generateAiImage(payLoad).then(r => {
                    const {_statusCode, responseData, message} = r
                    if(_statusCode === 203){
                        this.$swal({
                            title: this.$t(message),
                            iconHtml: this.$helpers.swalIcon('alert.png'),
                            showCancelButton: true,
                            confirmButtonText: this.$t('Upgrade'),
                            cancelButtonText: this.$t('Later'),
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-danger ml-1',
                                icon: 'border-0',
                            },
                            buttonsStyling: false,
                        }).then(result => {
                            if(result?.isConfirmed){
                                this.$router.push({
                                    name: `${this.route_type}-profile`, query: {
                                        tab: 'subscription'
                                    }
                                })
                            }
                        })
                    }else{
                        this.aiImage = responseData
                        this.imgk = Number(new Date)
                        this.title = null
                    }
                    this.loading = false
                    hideLoader()
                }).catch(e => {
                    if(e.response){
                        const { status, data } = e.response
                        if(data?.message && status === 500){
                            const { message } = data
                            showErrorNotification(this, message)
                        }
                    }
                    this.loading = false
                    hideLoader()
                });
            }
        },
    },
    watch: {
        aiModel(nv){
            if(nv == 'dall-e-3'){
                this.result = 1
            }
            if(!this.resolutionOpts.some(i => i.value == this.resolution)){
                this.resolution = '1024x1024'
            }
        }
    }

}
</script>