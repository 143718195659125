<template>
    <div class="card mb-0">
        <div class="product-box">
            <div class="product-img">
                <img class="img-fluid w-100" style="height: 250px;" :src="imgSrc" @error="$helpers.imageError">
                <div class="product-hover">
                    <ul>
                        <li>
                            <img width="32" class="cp mr-1" :src="require('@/assets/images/app-icons/zoom-to-fit-48.png')" @click="imageModal = true" />
                        </li>
                        <li v-if="isSaved">
                            <img width="32" class="cp" :src="require('@/assets/images/app-icons/icons8-download-48.png')" @click="$helpers.downloadFile(this, imgSrc)" />
                        </li>
                        <li v-else>
                            <img width="32" class="cp" :src="require('@/assets/images/app-icons/save-48.png')" @click="saveImage" :disabled="loading" />
                        </li>
                    </ul>
                </div>
                <div v-if="isImagePage" class="product-details">
                    <p class="mb-1">
                        <span class="text-muted">{{ $t('Prompt') }}:</span> {{ aiImageTree.query }}
                    </p>
                    <p class="mb-0">
                        <span class="text-muted">{{ $t('Size') }}:</span> {{ aiImageTree.params.size }}
                    </p>
                </div>
            </div>
        </div>
        <b-modal v-model="imageModal" centered size="lg" no-close-on-backdrop no-close-on-esc no-stacking ok-only>
            <img class="img-fluid" :src="imgSrc" @error="$helpers.imageError">
        </b-modal>
    </div>
</template>

<script>
import { isEmpty } from '@/utilities';
import { mapActions } from 'vuex';
import { showLoader, hideLoader } from '@/@core/comp-functions/ui/app';
export default {
    name: "GeneratedImage",
    props: {
        aiImageTree: Object,
    },
    data: () => ({
        loading: false,
        imageModal: false,
        frontEnd: process.env.VUE_APP_API_BASE_END_POINT,
        isSavedOnServer: false,
        savePath: null
    }),
    computed: {
        isSaved: {
            get() {
                if(!isEmpty(this.savePath)) return true;
                return !isEmpty(this.aiImageTree.saved_path)
            }
        },
        imgSrc: {
            get() {
                if(this.isSaved){
                    if(!isEmpty(this.savePath)){
                        return `${this.frontEnd}${this.savePath}`
                    }
                    return `${this.frontEnd}${this.aiImageTree.saved_path}`
                }
                return this.aiImageTree.image
            }
        },
        isImagePage: {
            get(){
                return this.$route.name == 'user-ai-images-saved'
            }
        }

    },
    methods: {
        isEmpty,
        ...mapActions('ai', [
            'saveGeneratedAiImage',
        ]),
        saveImage() {
            if (!isEmpty(this.aiImageTree)) {
                showLoader()
                this.loading = true
                const payLoad = {
                    id: this.aiImageTree.id,
                }
                this.saveGeneratedAiImage(payLoad).then(r => {
                    if(!isEmpty(r.saved_path)){
                        this.savePath = r.saved_path
                    }
                    this.loading = false
                    hideLoader()
                }).catch(e => {
                    this.loading = false
                    hideLoader()
                });
            }
        },
    }
}
</script>

<style lang="scss" scoped>

.product-box {
    border-radius: 10px;
    overflow: hidden;
}

.product-box .product-img {
    position: relative;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.product-box .product-img .product-hover {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    border-radius: 100%;
    transform: scale(0);
    transition: all 0.3s ease;

    ul {
        padding-left: 0px;
        list-style-type: none;
        margin-bottom: 0;

        li {
            display: inline-block;
            box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 8%);
            padding: 10px 12px;
            background-color: #fff;
            font-size: 18px;
            border-radius: 10px;
            height: 45px;
            width: 45px;
            margin: 0 3px;
            cursor: pointer;
            text-align: center;

            .btn {
                padding: 0;
            }
        }
    }
}

.product-box .product-details {
    padding: 15px 25px;
}

.product-box:hover .product-hover {
    opacity: 1;
    border-radius: 0%;
    transform: scale(1);
    transition: all 0.3s ease;
}

.product-box .product-hover {
    img {
        width: 20px;
    }
}
</style>
